import { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import TextFieldComponent from "../common/TextField";
import { VALIDATION_TYPE } from "../../config/enum";
import { PrimaryButton } from "../common/Buttons";
import { isValidEmail } from "../../utils/validator";
import { userForgotPasswordAsync } from "../../pages/auth/controller";
import { toast } from "react-toastify";

function ResetPassword() {
  const [passwordLoader, setPasswordLoader] = useState(false);

  const handleBlurEmail = (event: any) => {
    return new Promise((resolve) => {
      var email = event.target.value;
      if (!!email) {
        if (!isValidEmail(email)) resolve("Must be a valid email");
      } else resolve("");
    });
  };

  //@ts-ignore
  const handleResetPassword = (values, { setSubmitting }) => {
    setPasswordLoader(true);
    const email = values.email;
    userForgotPasswordAsync({ email })
      .then((res) => {
        if (res?.success) toast.success(res.message);
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(false);
        console.log("err: ", err);
      })
      .finally(() => setPasswordLoader(false));
  };

  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={handleResetPassword}
    >
      {({ errors, handleChange, handleSubmit, isSubmitting, values }) => {
        const resetBtnStatus = !!values?.email && !errors?.email;
        return (
          <form
            noValidate
            onSubmit={handleSubmit}
            style={{ paddingTop: "16px" }}
          >
            <TextFieldComponent
              label="Email Address"
              value={values.email}
              name={"email"}
              handler={handleChange}
              handleBlurEvent={handleBlurEmail}
              type={VALIDATION_TYPE.EMAIL}
              customStyles={{ pb: 2 }}
              required={false}
            />
            <PrimaryButton
              type="submit"
              fullWidth
              sx={{ my: 2 }}
              variant="contained"
              color="primary"
              loading={passwordLoader}
              disabled={!resetBtnStatus || isSubmitting}
            >
              Reset Password
            </PrimaryButton>
          </form>
        );
      }}
    </Formik>
  );
}

export default ResetPassword;
