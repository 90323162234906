import { APP_ROUTES } from "../../config/enum";
import { SidebarItemsType } from "../../types/sidebar";

import { Sliders, Users, Settings, Grid } from "react-feather";

const configuration = [
  {
    href: "/configuration",
    icon: Users,
    title: "User Configuration",
    children: [
      {
        href: APP_ROUTES.USERS_LIST_PAGE,
        title: "Users",
      },
      {
        href: APP_ROUTES.USER_TYPES_LIST_PAGE,
        title: "UserTypes",
      },
    ],
  },
  {
    href: "/configuration",
    icon: Settings,
    title: "System Configuration",
    children: [
      {
        href: APP_ROUTES.AGENCY_LIST_PAGE,
        title: "Agency",
      },
      {
        href: APP_ROUTES.DEPARTMENT_LIST_PAGE,
        title: "Department",
      },
      {
        href: APP_ROUTES.PROJECT_LIST_PAGE,
        title: "Project",
      },
      {
        href: APP_ROUTES.QUALIFICATION_LIST_PAGE,
        title: "Qualification",
      },
    ],
  },
] as SidebarItemsType[];

const pagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
    children: [
      {
        href: "/dashboard/default",
        title: "Default",
      },
    ],
  },
  {
    href: "/work",
    icon: Grid,
    title: "Work Module",
    children: [
      {
        href: APP_ROUTES.LEAD_LIST_PAGE,
        title: "Lead",
      },
      {
        href: APP_ROUTES.CONVERTED_LEAD_LIST_PAGE,
        title: "Converted Lead",
      },
      {
        href: APP_ROUTES.FLYED_LEAD_LIST_PAGE,
        title: "Flyed Lead",
      },
    ],
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
  {
    title: "CONFIGURATION",
    pages: configuration,
  },
];

export default navItems;
