import { Helmet } from "react-helmet-async";
import { Grid, Paper, Typography } from "@mui/material";
import SignInComponent from "../../components/auth/SignIn";
import logo from "../../vendor/logo_1.jpg";

function SignIn() {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Helmet title="Sign In" />
      <Grid item xs={12} md={12}>
        <Paper sx={{ mx: 12, px: 8, py: 8 }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ my: 4 }}
          >
            <img height="60px" src={logo} alt="Quince" loading="lazy" />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h6" align="center" sx={{ mb: 4 }}>
              Sign-in to continue
            </Typography>
            <SignInComponent />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default SignIn;
