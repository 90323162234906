type Config = {
  api_endpoint: string;
};

const config: Config = {
  // api_endpoint: "http://localhost:3000",
  api_endpoint: "https://api.quinceinternational.in",
};

export default config;
