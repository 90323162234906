import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { validateField } from "../../utils/validator";
import { VALIDATION_TYPE } from "../../config/enum";

interface IProps {
  required?: boolean;
  value?: string | number;
  label: string;
  name?: string;
  type: string;
  disabled?: boolean;
  maxLength?: number;
  handler: (event: any) => void;
  customStyles?: { [key: string]: any };
}
const PasswordField = (props: IProps) => {
  const { label, value, name, disabled, maxLength, handler, required } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMsg] = useState<string>("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChange = (event: any) => {
    const value = validateField(
      event.target.value,
      VALIDATION_TYPE.ALPHANUMERIC
    );
    if (!event.target.value) {
      setErrorMsg("");
    } else {
      setErrorMsg(value);
    }
    handler(event);
  };

  const handleBlur = (event: any) => {
    if (value) {
      const value = validateField(
        event.target.value,
        VALIDATION_TYPE.ALPHANUMERIC
      );
      if (!event.target.value.length) {
        setErrorMsg("");
      } else {
        setErrorMsg(value);
      }
    }
  };

  const Styles = {
    svg: {
      height: "18px",
    },
  };

  return (
    <>
      <PasswordFieldWrapper
        style={{
          paddingBottom:
            !!maxLength && !!errorMessage
              ? "0px"
              : !!maxLength
              ? "12px"
              : "0px",
        }}
      >
        <FormControl sx={{ width: "100%", mt: "8px" }} variant="outlined">
          <InputLabel
            htmlFor="outlined-adornment-password"
            sx={{
              top: value ? "0px" : "-3px",
            }}
          >
            {label}
          </InputLabel>
          <OutlinedInput
            sx={{ ...Styles }}
            size={"small"}
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            onChange={handleChange}
            value={value}
            error={!!errorMessage}
            name={name}
            onBlur={handleBlur}
            disabled={disabled}
            inputProps={{ maxLength: maxLength }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        {errorMessage && (
          <p style={{ color: "#f44336", marginTop: "4px", fontSize: "11px" }}>
            {errorMessage}
          </p>
        )}
        {maxLength && (
          <div
            style={{
              position: "absolute",
              right: "5px",
              bottom: !!errorMessage ? "6px" : "-4px",
              fontSize: "10px",
              color: "rgba(255,255,255,0.5)",
            }}
          >
            {(value || "").toString().length}/{maxLength}
          </div>
        )}
      </PasswordFieldWrapper>
    </>
  );
};

const PasswordFieldWrapper = styled.div`
  position: relative;
  label[data-shrink="false"] {
    top: -7px;
  }
`;

export default PasswordField;
