import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import axios from "./axios";
import { clearLocalStorageExceptOne } from "./common";

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = () => {
  clearLocalStorageExceptOne("theme");
  delete axios.defaults.headers.common.Authorization;
};

export { verify, sign, isValidToken, setSession };
