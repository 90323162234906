export const characterLimit = (str: string, limit: number) => {
  return str.length > limit ? str.substring(0, limit) + "..." : str;
};

export function dateFormat(data: any) {
  const date = new Date(data);
  const formattedDate = date
    .toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    })
    .replace(/ /g, "-");
  return formattedDate;
}

export function removeEmptyObjects(array: any) {
  const newArray = array.filter((element: any) => {
    if (Object.keys(element).length !== 0) {
      return true;
    }

    return false;
  });

  return newArray;
}
