import LoadingButton from "@mui/lab/LoadingButton";
import styled from "styled-components";

type IButtonProps = {
  theme?: any;
};

export const PrimaryButton = styled(LoadingButton)<IButtonProps>`
  background: ${(props) => props.theme.palette.primary.main};
  color: white;
  padding: 8px 20px;
  min-width: 100px;
  &:disabled {
    // color: rgba(255, 255, 255, 0.3);
    box-shadow: none;
    background-color: rgba(255, 255, 255, 0.12);
  }
  &:hover,
  &:focus {
    outline: none;
    background: ${(props) => props.theme.palette.primary.main};
  }
`;

export const SecondaryButton = styled(LoadingButton)<IButtonProps>`
  background: #c8d5eb;
  color: ${(props) => props.theme.palette.primary.main};
  padding: 8px 20px;
  min-width: 100px;
  &:hover,
  &:focus {
    outline: none;
    background: #c8d5eb;
  }
`;

export const OutlineButton = styled(LoadingButton)<IButtonProps>`
  padding: 8px 20px;
  min-width: 100px;
  color: white;
  border: 1px solid;
  border-color: white !important;
  &:disabled {
    border-color: rgba(255, 255, 255, 0.12) !important;
  }
`;

export const BlueOutlinedButton = styled(LoadingButton)<IButtonProps>`
  padding: 8px 20px;
  min-width: 100px;
  color: var(--primaryColor);
  border-color: var(--primaryColor);
`;
