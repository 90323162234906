export const formatLoginAPIResponse = (res: any) => {
  try {
    if (res) {
      var user = {
        userName: res?.data?.firstName + " " + res?.data?.lastName,
        email: res?.data?.email,
        id: res?.data?.userId,
        userTypeFeature: res?.data?.userTypeFeature,
        loginStatus: res?.success,
      };
      return { data: { accessToken: res?.data?.accessToken, user: user } };
    } else return { data: { accessToken: "", user: {} } };
  } catch (err) {
    console.log("err: ", err);
    return { data: { accessToken: "", user: {} } };
  }
};
