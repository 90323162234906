import { createContext, ReactNode, useEffect } from "react";
import { JWTContextType } from "../types/auth";
import { setSession } from "../utils/jwt";
import {
  getAccessTokenFromStorage,
  setTokenToLocalStorage,
} from "../utils/common";
import { useDispatch } from "react-redux";
import { userLoginAsync } from "../pages/auth/controller";
import { formatLoginAPIResponse } from "../helpers/login.helper";
import axiosInstance from "../utils/axios";
import { INITIALIZE, SET_USER_DETAILS, SIGN_OUT } from "../redux/slices/user";

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken: any = getAccessTokenFromStorage();
        if (!accessToken) {
          dispatch(
            INITIALIZE({
              isAuthenticated: false,
              user: null,
            })
          );
        }
      } catch (err) {}
    };

    initialize();
  }, []);

  const signIn = async (email: string, password: string) => {
    try {
      const response = await userLoginAsync({ email, password })
        .then((res) => formatLoginAPIResponse(res))
        .catch((err) => {
          console.log(err);
          return { data: { accessToken: "", user: {} } };
        });
      const { accessToken, user } = response.data;
      //@ts-ignore
      if (user?.loginStatus) {
        try {
          //trying to set token at axios instance
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${accessToken}`;
        } catch (err) {
          console.log("setting token error: ", err);
        }
        setTokenToLocalStorage(accessToken);
        dispatch(
          SET_USER_DETAILS({
            isAuthenticated: true,
            user: user,
          })
        );
        return user;
      } else {
        dispatch(
          INITIALIZE({
            isAuthenticated: false,
            user: null,
          })
        );
      }
    } catch (err) {
      console.log("err: ", err);
    }
    return null;
  };

  const signOut = async () => {
    setSession();
    dispatch(SIGN_OUT());
  };

  return (
    <AuthContext.Provider
      value={{
        method: "jwt",
        //@ts-ignore
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
