import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Grid } from "@mui/material";
import PasswordField from "../common/PasswordField";
import { PrimaryButton } from "../common/Buttons";
import { toast } from "react-toastify";
import { isValidPassword } from "../../utils/validator";
import { userSetPasswordAsync } from "../../pages/auth/controller";
import { APP_ROUTES } from "../../config/enum";

function PasswordForm() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [passwordLoader, setPasswordLoader] = useState(false);
  const [token, setToken] = useState<string | null>("");

  useEffect(() => {
    const token = searchParams.get("accessToken");
    setToken(token);
    if (!token) navigate(APP_ROUTES.PAGE_NOT_FOUND);
  }, [searchParams]);

  //@ts-ignore
  const handleSetPassword = async (values, { setSubmitting }) => {
    setPasswordLoader(true);
    const password = values.password;
    if (!isValidPassword(values.password)) {
      toast.error(
        "Password should be alphanumeric and it should contain 8 characters"
      );
      setPasswordLoader(false);
      setSubmitting(false);
    } else {
      if (values.password === values.confirmPassword) {
        const payload = {
          password: password,
          token: token,
        };
        userSetPasswordAsync(payload)
          .then((res) => {
            if (res?.success) {
              toast.success(res?.message);
              navigate(APP_ROUTES.SIGN_IN_PAGE);
            } else {
              toast.error(res?.message);
              navigate(APP_ROUTES.PAGE_NOT_FOUND);
            }
          })
          .catch((err) => {
            setSubmitting(false);
            console.log("err: ", err);
          })
          .finally(() => setPasswordLoader(false));
      } else {
        toast.error("Passwords dont match");
        setSubmitting(false);
        setPasswordLoader(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().max(255).required("Password is required"),
        confirmPassword: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={handleSetPassword}
    >
      {({ errors, handleChange, handleSubmit, isSubmitting, values }) => {
        const createBtnStatus =
          !!values?.password &&
          !errors?.password &&
          !!values?.confirmPassword &&
          !errors?.confirmPassword;
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Grid item style={{ marginTop: 4 }}>
              <PasswordField
                required
                label="Password"
                name={"password"}
                value={values.password}
                handler={handleChange}
                type={""}
              />
            </Grid>
            <Grid item style={{ marginTop: 4 }}>
              <PasswordField
                required
                label="Confirm Password"
                name={"confirmPassword"}
                value={values.confirmPassword}
                handler={handleChange}
                type={""}
              />
            </Grid>
            <PrimaryButton
              type="submit"
              fullWidth
              sx={{ my: 2, mt: 4 }}
              variant="contained"
              color="primary"
              loading={passwordLoader}
              disabled={!createBtnStatus || isSubmitting}
            >
              Create Password
            </PrimaryButton>
          </form>
        );
      }}
    </Formik>
  );
}

export default PasswordForm;
