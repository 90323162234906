const regexRegistery = {
  alphaNumericRegex: /\d*[a-zA-Z][a-zA-Z\d]*/,
  alphaNumericWithSlashRegex: /\d*[a-zA-Z/][a-zA-Z\d]*/,
  numericRegex: /^\d+(\.\d*)?$/,
  domainRegex:
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g,
  wordRegex: /^(?!_+$)[A-Z_]+$/,
};

const isAlphaNumeric = (value = "") =>
  value && value.match(regexRegistery.alphaNumericRegex);

const isAlphaNumericWithSlashRegex = (value = "") =>
  value && value.match(regexRegistery.alphaNumericWithSlashRegex);

const isWordCharacter = (value = "") =>
  value && value.match(regexRegistery.wordRegex);

const isNumeric = (value = "") =>
  value && value.match(regexRegistery.numericRegex);

const isMinimumLength = (value: string = "") => value && value.length > 1;

const isDomain = (value: string) => {
  if (value.match(regexRegistery.domainRegex)) return true;
  else return false;
};

export {
  isAlphaNumeric,
  isAlphaNumericWithSlashRegex,
  isNumeric,
  isMinimumLength,
  isDomain,
  isWordCharacter,
  regexRegistery,
};
