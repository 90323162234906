import axios from "axios";
import { INVALID_TOKEN_CODE } from "../config/constants";
import { clearLocalStorageExceptOne } from "./common";
import { APP_ROUTES } from "../config/enum";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error.response, "rsp");
    if (error?.response?.status === INVALID_TOKEN_CODE) {
      clearLocalStorageExceptOne("theme");
      window.location.href = APP_ROUTES.SIGN_IN_PAGE;
    } else {
      return Promise.reject(
        (error?.response && error?.response?.data) || "Something went wrong"
      );
    }
  }
);

export default axiosInstance;
