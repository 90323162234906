function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function profilePic(name: string = "") {
  const formattedName = (name || "").trim();
  let firstLetter = "";
  let secondLetter = "";
  if (formattedName.indexOf(" ") >= 0) {
    firstLetter = formattedName.split(" ")[0][0] || "A";
    secondLetter = formattedName.split(" ")[1][0] || "";
  } else {
    firstLetter = formattedName.charAt(0);
  }
  return {
    sx: {
      bgcolor: stringToColor(formattedName || ""),
      color: "#fff",
    },
    children: `${(firstLetter || "").toUpperCase()}${(
      secondLetter || ""
    ).toUpperCase()}`,
  };
}

export { profilePic };
