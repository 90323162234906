import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert as MuiAlert,
  Checkbox,
  FormControlLabel,
  Button,
  Grid,
} from "@mui/material";
import { spacing } from "@mui/system";
import useAuth from "../../hooks/useAuth";
import secureLocalStorage from "react-secure-storage";
import TextFieldComponent from "../common/TextField";
import { VALIDATION_TYPE } from "../../config/enum";
import { isValidEmail } from "../../utils/validator";
import PasswordField from "../common/PasswordField";
import { PrimaryButton } from "../common/Buttons";

const Alert = styled(MuiAlert)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [loginLoader, setloginLoader] = useState(false);

  const credentialsFromLocalStorage =
    secureLocalStorage.getItem("userCredentials");

  const [userCredentials, setUserCredentials] = useState({
    //@ts-ignore
    email: credentialsFromLocalStorage ? credentialsFromLocalStorage.email : "",
    password: credentialsFromLocalStorage
      ? //@ts-ignore
        credentialsFromLocalStorage.password
      : "",
    remember: credentialsFromLocalStorage
      ? //@ts-ignore
        credentialsFromLocalStorage.remember
      : false,
  });

  const handleBlurEmail = (event: any) => {
    return new Promise((resolve) => {
      var email = event.target.value;
      if (!!email) {
        if (!isValidEmail(email)) resolve("Must be a valid email");
      } else resolve("");
    });
  };

  const handleRememberMe = (event: any, values: any) => {
    if (event.target.checked) {
      secureLocalStorage.setItem("userCredentials", {
        email: values.email,
        password: values.password,
        remember: event.target.checked,
      });
    } else {
      secureLocalStorage.removeItem("userCredentials");
    }
  };

  const forgotPassword = () => {
    navigate("/auth/reset-password");
  };

  //@ts-ignore
  const handleSignIn = (values: any, { setSubmitting }) => {
    setloginLoader(true);
    signIn(values.email, values.password)
      .then((res: any) => {
        if (res?.loginStatus) navigate("/");
        setSubmitting(false);
      })
      .finally(() => {
        setloginLoader(false);
      });
  };

  return (
    <Formik
      initialValues={{
        email: userCredentials.email,
        password: userCredentials.password,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={handleSignIn}
    >
      {({ errors, handleChange, handleSubmit, isSubmitting, values }) => {
        const signInBtnStatus =
          !!values?.email &&
          !!values?.password &&
          !errors?.email &&
          !errors?.password;
        return (
          <form noValidate onSubmit={handleSubmit}>
            <TextFieldComponent
              label="Email Address"
              value={values.email}
              name={"email"}
              handler={handleChange}
              handleBlurEvent={handleBlurEmail}
              type={VALIDATION_TYPE.EMAIL}
              customStyles={{ pb: 2 }}
              required={false}
            />
            <PasswordField
              required
              label="Password"
              name={"password"}
              value={values.password}
              handler={handleChange}
              type={""}
            />
            <Grid item xs={12} md={12} style={{ display: "flex" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    onChange={(event) => handleRememberMe(event, values)}
                    defaultChecked={userCredentials.remember ? true : false}
                    id={"sign-in-remember-me_checkbox"}
                  />
                }
                label="Remember me"
              />
              <Button onClick={forgotPassword} style={{ marginLeft: "auto" }}>
                Forgot Password?
              </Button>
            </Grid>
            <PrimaryButton
              type="submit"
              fullWidth
              sx={{ my: 3 }}
              variant="contained"
              color="primary"
              loading={loginLoader}
              disabled={!signInBtnStatus || isSubmitting}
            >
              Sign in
            </PrimaryButton>
          </form>
        );
      }}
    </Formik>
  );
}

export default SignIn;
