import { VALIDATION_TYPE } from "../config/enum";
import {
  isAlphaNumeric,
  isAlphaNumericWithSlashRegex,
  isDomain,
  isMinimumLength,
  isWordCharacter,
  regexRegistery,
} from "./regex";

const isValidEmail = (id: string = "") => {
  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (id && id.length > 3) {
    return emailRegex.test(id);
  } else {
    return false;
  }
};

const isValidEmailwithComma = (id: string) => {
  const emailWithCommaRegex = /^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4}\s*,?\s*)+$/;
  return emailWithCommaRegex.test(id);
};

const validateField = (value: string, type: string) => {
  if (type === VALIDATION_TYPE.ALPHANUMERIC) {
    if (!!value) {
      if (!isAlphaNumeric(value)) return "Please enter alphanumeric character";
      else return "";
    } else return "";
  } else if (type === VALIDATION_TYPE.DOMAIN) {
    if (!isDomain(value)) return "Please enter valid domain";
    else return "";
  } else if (type === VALIDATION_TYPE.ALPHANUMERICWITHSLASH) {
    if (!!value) {
      if (!isAlphaNumericWithSlashRegex(value))
        return "Please enter alphanumeric character";
      else return "";
    } else return "";
  } else if (type === VALIDATION_TYPE.NUMERIC) {
    if (!regexRegistery.numericRegex.test(value))
      return "Please enter numeric character only";
    else return "";
  } else if (type === VALIDATION_TYPE.SELECT) {
    if (!value) return "Please enter atleast one value from dropdown list";
    else return "";
  } else if (type === VALIDATION_TYPE.ALPHABETWITHUNDERSCORE) {
    if (!!value) {
      if (!isWordCharacter(value.toUpperCase()))
        return "Please enter valid identifier";
      else return "";
    } else return "";
  } else return "";
};

const validateLength = (value: string = "") => {
  if (!isMinimumLength(value)) return "Minimum 2 characters";
  else return "";
};

const isValidPassword = (id: string = "") => {
  const passwordRegex = /\d*[a-zA-Z][a-zA-Z\d]*/;
  if (id && id.length > 7) {
    return passwordRegex.test(id);
  } else {
    return false;
  }
};

export {
  isValidEmail,
  validateField,
  validateLength,
  isValidEmailwithComma,
  isValidPassword,
};
