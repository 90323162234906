import secureLocalStorage from "react-secure-storage";
import { ACCESS_TOKEN } from "../config/constants";

const getAccessTokenFromStorage = () => {
  try {
    const token = secureLocalStorage.getItem(ACCESS_TOKEN);
    if (token) {
      return token;
    }
    return "";
  } catch (err) {
    return "";
  }
};

const setTokenToLocalStorage = (token: string) =>
  secureLocalStorage.setItem(ACCESS_TOKEN, token);

const clearLocalStorage = () => localStorage.clear();

const clearLocalStorageExceptOne = (keyToPreserve: string) => {
  Object.keys(localStorage).forEach((key) => {
    if (key !== keyToPreserve) {
      localStorage.removeItem(key);
    }
  });
};

export {
  getAccessTokenFromStorage,
  setTokenToLocalStorage,
  clearLocalStorage,
  clearLocalStorageExceptOne,
};
