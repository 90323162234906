import { getAccessTokenFromStorage } from "./common";
import requestConfig from "../config/request";
import axiosInstance from "./axios";

async function getRequestConfig(apiConfig?: any) {
  let config: any = Object.assign({}, requestConfig);
  if (apiConfig) {
    config = { ...config, ...apiConfig };
  }

  if (config.headers) {
    if (!config.headers.Authorization) {
      const token = getAccessTokenFromStorage(); // get token from localStorage...
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
  }
  return config;
}

const get = async (url: string, params?: string, apiConfig?: any) => {
  const config = await getRequestConfig(apiConfig);
  config.params = params;
  const response = await axiosInstance.get(url);
  return response?.data;
};

const post = async (url: string, data: any, apiConfig?: any) => {
  try {
    const config = await getRequestConfig(apiConfig);
    axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
    const response = await axiosInstance.post(
      url,
      JSON.stringify(data),
      config
    );
    return response?.data;
  } catch (error: any) {
    console.log("Error in post request.", error);
    throw new Error("Something went wrong");
  }
};

const put = async (url: string, data: any) => {
  try {
    const config = await getRequestConfig();
    config.headers["Content-Type"] = "application/json";
    const response = await axiosInstance.put(url, JSON.stringify(data), config);
    return response?.data;
  } catch (error: any) {
    throw new Error("Something went wrong");
  }
};

const deleteRequest = async (url: string) => {
  try {
    const config = await getRequestConfig();
    const response = await axiosInstance.delete(url, config);
    return response?.data;
  } catch (error: any) {
    throw new Error("Something went wrong");
  }
};

export { get, post, put, deleteRequest };
