/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";
import { UserState } from "../../types/reducer.types";

const initialState: UserState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    INITIALIZE: (state: UserState, action: any) => {
      (state.isAuthenticated = action.payload.isAuthenticated),
        (state.isInitialized = true),
        (state.user = null);
    },
    SET_USER_DETAILS: (state: UserState, action: any) => {
      (state.isAuthenticated = true),
        (state.isInitialized = true),
        (state.user = action.payload.user);
    },
    SIGN_OUT: (state: UserState) => {
      (state.isAuthenticated = false),
        (state.isInitialized = true),
        (state.user = null);
    },
  },
});

export const { INITIALIZE, SET_USER_DETAILS, SIGN_OUT } = userSlice.actions;

export default userSlice.reducer;
