import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/user";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  user: userReducer,
});

export const store = configureStore({
  reducer: {
    user: userReducer,
  },
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
