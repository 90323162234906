import { TextField } from "@mui/material";
import { useState } from "react";
import { validateField, validateLength } from "../../utils/validator";
import { OverridableStringUnion } from "@mui/types";
import { VALIDATION_TYPE } from "../../config/enum";
interface TextFieldPropsSizeOverrides {}
interface IProps {
  className?: string;
  value?: string | number | boolean | null;
  label?: string;
  name?: string;
  type: string;
  isSelect?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  children?: any;
  maxLength?: number;
  defaultValue?: string;
  validationNotRequired?: boolean;
  required?: boolean;
  placeholder?: string;
  inputLabelProps?: { [key: string]: boolean };
  handler: (event: any) => void;
  handleBlurEvent?: (event: any) => any;
  customStyles?: { [key: string]: any };
  size?: OverridableStringUnion<
    "small" | "medium",
    TextFieldPropsSizeOverrides
  >;
  fieldId?: string;
  from?: string;
  variant?: string;
  InputProps?: { [key: string]: any };
}
const TextFieldComponent = (props: IProps) => {
  const {
    className,
    value,
    label,
    name,
    type,
    isSelect,
    disabled,
    multiline,
    rows,
    children,
    maxLength,
    defaultValue,
    validationNotRequired,
    required,
    size = "small",
    placeholder,
    inputLabelProps,
    handler,
    handleBlurEvent,
    customStyles,
    fieldId,
    from,
    variant,
    InputProps,
  } = props;
  const [errorMessage, setErrorMsg] = useState<string>("");

  const handleChange = (event: any) => {
    const ipValue = event.target.value;
    if (from !== "flowchart") {
      const value = ipValue ? validateField(ipValue, type) : "";
      if (validationNotRequired && !ipValue) setErrorMsg("");
      else setErrorMsg(value);
    }
    handler(event);
  };

  const handleBlur = (event: any) => {
    const ipValue = event.target.value;
    if (from !== "flowchart") {
      const value = ipValue ? validateField(ipValue, type) : "";
      if (validationNotRequired) {
        if (ipValue) {
          if (!ipValue.length) setErrorMsg("");
          else setErrorMsg(value);
        } else setErrorMsg("");
      } else setErrorMsg(value);
      if (
        !value &&
        type !== VALIDATION_TYPE.SELECT &&
        type !== VALIDATION_TYPE.NUMERIC
      ) {
        if (ipValue.length === 0 && !validationNotRequired) {
          // setErrorMsg("Please enter " + label);
        }
        if (
          ipValue.length !== 0 &&
          type !== VALIDATION_TYPE.ALPHANUMERICWITHSLASH
        ) {
          const value = validateLength(ipValue);
          setErrorMsg(value);
        }
      }
    }
    if (!!handleBlurEvent) {
      if (from !== "flowchart") {
        handleBlurEvent(event).then((data: any) => {
          setErrorMsg(data);
        });
      } else {
        handleBlurEvent(event);
      }
    }
  };

  const Styles = {
    borderColor: "#fff",
    m: 0,
    mt: 2,
    width: 1,
    ".MuiFormHelperText-root-eKiDjY": {
      marginLeft: 0,
      marginRight: "30px",
    },
    ...customStyles,
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          paddingBottom:
            !!maxLength && !!errorMessage
              ? "0px"
              : !!maxLength
              ? "12px"
              : "0px",
        }}
      >
        <TextField
          required={required === undefined ? true : required}
          id={fieldId}
          size={size}
          className={className || ""}
          sx={{ ...Styles }}
          label={label}
          onChange={handleChange}
          value={value}
          error={!!errorMessage}
          helperText={errorMessage}
          name={name}
          onBlur={!disabled ? handleBlur : () => {}}
          type={type}
          select={isSelect}
          disabled={disabled}
          multiline={multiline}
          rows={rows}
          inputProps={{ maxLength: maxLength }}
          defaultValue={defaultValue}
          placeholder={placeholder}
          InputLabelProps={inputLabelProps}
          //@ts-ignore
          variant={variant === undefined ? "outlined" : variant}
          InputProps={InputProps}
        >
          {children}
        </TextField>
        {maxLength && (
          <div
            style={{
              position: "absolute",
              right: "5px",
              bottom: !!errorMessage ? "6px" : "-4px",
              fontSize: "10px",
              color: "rgba(255,255,255,0.5)",
            }}
          >
            {(value || "").toString().length}/{maxLength}
          </div>
        )}
      </div>
    </>
  );
};

export default TextFieldComponent;
