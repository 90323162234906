import { toast } from "react-toastify";
import {
  userForgotPasswordAPI,
  userSetPasswordAPI,
  userSignInAPI,
} from "../../services/user";
import {
  ForgotPasswordAPIPayloadType,
  LoginAPIPayloadType,
  SetPasswordAPIPayloadType,
} from "../../types/user.type";

const userLoginAsync = (payload: LoginAPIPayloadType) => {
  return userSignInAPI(payload)
    .then((resp) => {
      if (resp.success) return resp;
      else toast.error(resp.message || "Something went wrong");
    })
    .catch((err) => {
      toast.error("Something went wrong");
      throw new Error(err || "Something went wrong");
    });
};

const userForgotPasswordAsync = (payload: ForgotPasswordAPIPayloadType) => {
  return userForgotPasswordAPI(payload)
    .then((resp) => {
      if (resp?.success) return resp;
      else toast.error(resp?.message || "Something went wrong");
    })
    .catch((err) => {
      toast.error("Something went wrong");
      throw new Error(err || "Something went wrong");
    });
};

const userSetPasswordAsync = (payload: SetPasswordAPIPayloadType) => {
  return userSetPasswordAPI(payload)
    .then((resp) => {
      return resp;
    })
    .catch((err) => {
      toast.error("Something went wrong");
      throw new Error(err || "Something went wrong");
    });
};

export { userLoginAsync, userForgotPasswordAsync, userSetPasswordAsync };
