import config from "../config/apiConfig";
import { BodyTypePayload } from "../types/common.types";
import {
  AddEditUserAPIPayloadType,
  ForgotPasswordAPIPayloadType,
  LoginAPIPayloadType,
  SetPasswordAPIPayloadType,
} from "../types/user.type";
import * as API from "../utils/apiMethods";

const fetchUsersAPI = (body: BodyTypePayload, params?: string) => {
  let URL = `${config.api_endpoint}/users/getUsers${params || ""}`;
  return API.post(URL, body);
};

const addNewUserAPI = (payload: AddEditUserAPIPayloadType) => {
  const URL = `${config.api_endpoint}/users/createUser`;
  return API.post(URL, payload);
};

const editUserAPI = (payload: AddEditUserAPIPayloadType) => {
  const URL = `${config.api_endpoint}/users/editUser`;
  return API.put(URL, payload);
};

const deleteUserAPI = (params: string) => {
  const URL = `${config.api_endpoint}/users/deleteUser?${params}`;
  return API.deleteRequest(URL);
};

const userSignInAPI = (payload: LoginAPIPayloadType) => {
  const URL = `${config.api_endpoint}/users/login`;
  return API.post(URL, payload);
};

const userForgotPasswordAPI = (payload: ForgotPasswordAPIPayloadType) => {
  const URL = `${config.api_endpoint}/users/forgotPassword`;
  return API.post(URL, payload);
};

const userSetPasswordAPI = (payload: SetPasswordAPIPayloadType) => {
  const URL = `${config.api_endpoint}/users/setPassword`;
  return API.post(URL, payload);
};

export {
  fetchUsersAPI,
  addNewUserAPI,
  editUserAPI,
  deleteUserAPI,
  userSignInAPI,
  userForgotPasswordAPI,
  userSetPasswordAPI,
};
