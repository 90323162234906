enum APP_ROUTES {
  USERS_LIST_PAGE = "/manage-users",
  USER_TYPES_LIST_PAGE = "/manage-usertypes",
  ADD_USER_TYPES = "/manage-usertypes/create-usertype",
  AGENCY_LIST_PAGE = "/manage-agency",
  DEPARTMENT_LIST_PAGE = "/manage-department",
  PROJECT_LIST_PAGE = "/manage-project",
  QUALIFICATION_LIST_PAGE = "/manage-qualification",
  LEAD_LIST_PAGE = "/manage-lead",
  CONVERTED_LEAD_LIST_PAGE = "/manage-converted-lead",
  FLYED_LEAD_LIST_PAGE = "/manage-flyed-lead",
  DASHBOARD = "/",
  AUTH_ROOT = "/auth",
  SIGN_IN_PAGE = "/auth/sign-in",
  PAGE_NOT_FOUND = "/auth/404",
}

enum SORT_TYPE {
  ASEC = "asc",
  DEC = "desc",
}

enum ACTION_TYPE {
  ADD = "ADD",
  EDIT = "EDIT",
  LIST = "LIST",
  DELETE = "DELETE",
  CONFIG = "CONFIG",
}

enum ACTION_BUTTON_TEXT {
  SUBMIT = "Submit",
  SAVE = "Save",
  DISCARD = "Discard",
  CANCEL = "Cancel",
  ASSIGN = "Assign",
  CREATE = "Create",
  UPDATE = "Update",
  OK = "Ok",
}

enum VALIDATION_TYPE {
  ALPHANUMERIC = "alphanumeric",
  NUMERIC = "numeric",
  SELECT = "select",
  ALPHANUMERICWITHSLASH = "alphanumericwithslash",
  DOMAIN = "domain",
  EMAIL = "email",
  ALPHABETWITHUNDERSCORE = "alphabetwithunderscore",
}

enum TABLE_FILTER_RELATION {
  EQ = "eq",
  GE = "ge",
  LE = "le",
  IN = "in",
}

enum LEAD_STATUS {
  LEAD = 1,
  CONVERTED = 2,
  FLYED = 3,
}

enum PERMISSIONS_IDS_ENUMS {
  VIEW = "view",
  ADD = "add",
  EDIT = "edit",
  DELETE = "delete",
  STATUS = "status",
  IS_CRUD = "is_crud",
}

export const PERMISSIONS_IDS_LIST = [
  { id: 1, key: PERMISSIONS_IDS_ENUMS.VIEW, name: "View" },
  { id: 2, key: PERMISSIONS_IDS_ENUMS.ADD, name: "Add" },
  { id: 3, key: PERMISSIONS_IDS_ENUMS.EDIT, name: "Edit" },
  { id: 4, key: PERMISSIONS_IDS_ENUMS.DELETE, name: "Delete" },
];

export const CRUD_PERMISSION_ID = {
  id: 5,
  key: PERMISSIONS_IDS_ENUMS.STATUS,
  name: "True",
};

export {
  APP_ROUTES,
  SORT_TYPE,
  ACTION_TYPE,
  ACTION_BUTTON_TEXT,
  VALIDATION_TYPE,
  TABLE_FILTER_RELATION,
  LEAD_STATUS,
  PERMISSIONS_IDS_ENUMS,
};
